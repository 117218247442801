<template>
  <div :class="classObject" :style="styleObject" class="flex flex-col v-stack">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { GapSizes, GapSizing, GapSizingEM } from "./";

export default Vue.extend({
  name: "VStack",
  props: {
    gap: {
      type: String as PropType<GapSizes>,
      default: "0",
    },
    center: {
      type: Boolean,
      default: false,
    },
    useEmSizing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    gapSizing(): string {
      return this.useEmSizing ? GapSizingEM[this.gap] : GapSizing[this.gap];
    },
    classObject(): { [key: string]: boolean } {
      return {
        "items-center": this.center,
      };
    },
    styleObject(): { [key: string]: string } {
      return {
        "--vstack-gap-sizing": this.gapSizing,
      };
    },
  },
});
</script>

<style scoped>
.v-stack > *:first-child {
  margin-top: unset;
}

.v-stack > * {
  margin-top: var(--vstack-gap-sizing);
}

@tailwind utilities;
</style>
