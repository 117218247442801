import axios from "axios";
import qs from "qs";
import { _csrf, AuthLoginResponse, AuthServiceOptions, Region } from "./AuthTypes";

export default class AuthService {
  private readonly _csrf: _csrf;
  private readonly region: Region;
  public readonly PHONE_NUMBER_PATTERN: string;

  constructor(options: AuthServiceOptions) {
    this.region = options.region;
    this._csrf = options._csrf;

    if (this.region === "uk") {
      this.PHONE_NUMBER_PATTERN = "^\\s*[+]?([.\\-()]?[0-9][.\\-()]?\\s*){7,15}$";
    } else {
      this.PHONE_NUMBER_PATTERN = "^(\\+1)?[\\s]*(\\()?[0-9]{3}(\\))?((\\s*|-?)[0-9]){7}$";
    }
  }

  async captchaStatus(): Promise<boolean> {
    return axios.get(`/rest/user-service/recaptchaStatus`).then((data) => data.data.requiresCaptcha);
  }

  async checkUsername(username: string): Promise<void> {
    return axios.get(`/rest/user-service/checkUsername?username=${encodeURIComponent(username)}`).then(() => {
      return;
    });
  }

  async login(formData: FormData): Promise<AuthLoginResponse> {
    formData.append(this._csrf.parameterName, this._csrf.token);

    const data = {};
    for (const el of formData.entries()) {
      data[el[0]] = el[1];
    }

    return axios
      .post("/login", qs.stringify(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const { redirectUrl } = response.data;
        return { redirectUrl };
      })
      .catch((error) => {
        let message: string;

        if (error?.isAxiosError) {
          message = error?.response?.data?.errorMessage;
        }

        if (error?.response?.status) {
          message = message || `Something went wrong. Status code: ${error.response.status}`;
        }

        message = message || "Something went wrong.";
        throw { messagesList: [message] };
      });
  }

  async recoverPassword(formData: FormData): Promise<void> {
    formData.append(this._csrf.parameterName, this._csrf.token);
    return axios
      .post("/rest/user-service/resetPassword", formData)
      .then((response) => {
        window.location.href = "/login-choose?" + $.param({ message: response.data.message }) + "#login";
      })
      .catch((response) => {
        const error = (response.data.responseJSON && response.data.responseJSON.message) || "Unknown error";
        window.location.href = "/login-choose?" + $.param({ error: error }) + "#login";
        throw undefined;
      });
  }

  async register(formData: FormData): Promise<(() => void) | void> {
    formData.append(this._csrf.parameterName, this._csrf.token);

    const login = () => {
      const loginFormData = new FormData();
      loginFormData.append("username", formData.get("usernameReg") as string);
      loginFormData.append("password", formData.get("passwordReg") as string);
      this.login(loginFormData).then(({ redirectUrl }) => {
        window.location.replace(redirectUrl as string);
      });
    };

    return axios
      .post("/rest/user-service/newUser", formData)
      .then(() => {
        return login;
      })
      .catch((error) => {
        const responseData = error.response.data || { status: "failure" };
        if (responseData.error == "InvalidRequest") {
          throw [responseData.errorDetails];
        }
        if (responseData.error == "AccessDenied") {
          throw ["Registration Error: Missing or invalid CAPTCHA", true];
        }
        throw [responseData.message];
      });
  }
}
