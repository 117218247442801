<template>
  <div class="pt-[24px] md:pt-0">
    <VStack class="bg-[#EDF0FA] rounded-[12px] p-[16px] w-[327px] md:w-[461px] md:bg-inherit md:px-0 md:pb-0">
      <h2 class="main-text-color mb-[12px] font-[700] text-[20px] md:text-[32px] md:text-center leading-snug">
        Continue to see what treatments are suitable for you
      </h2>
      <h4 class="main-text-color text-[13px] font-[400] mb-[8px] md:text-center">Treatments we offer include:</h4>
      <HStack class="text-[#222222] justify-between md:justify-center gap-[7.5px] text-[13px]">
        <div
          class="bg-white/60 h-[40px] px-[8px] rounded-[6px] flex items-center"
          v-for="product in products"
          :key="product.name"
          @click="onClickProduct(product.name)"
        >
          <img
            :src="product.image"
            :alt="`${product.name} pen`"
            class="max-h-[40px] max-w-[85px] mix-blend-darken"
            :class="product.class"
          />
        </div>
      </HStack>
    </VStack>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import VStack from "../Layout/Stack/VStack.vue";
import HStack from "../Layout/Stack/HStack.vue";
import { amplitudeEvent } from "../MXPixel/MedexpressAmplitude";
import Wegovy from "./assets/wegovy.png";
import Mounjaro from "./assets/mounjaro.png";
import Alli from "./assets/alli.png";

export default Vue.extend({
  components: {
    VStack,
    HStack,
  },
  computed: {
    products() {
      return [
        { name: "Wegovy®", image: Wegovy, class: "pt-[8px]" },
        { name: "Mounjaro®", image: Mounjaro },
        { name: "Alli®", image: Alli },
      ];
    },
  },
  methods: {
    onClickProduct(productName: string) {
      amplitudeEvent("Login Product Card Clicked", {
        Treatment: productName,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@theme/Main.scss";
</style>
