export { default as HStack } from "./HStack.vue";
export { default as VStack } from "./VStack.vue";

export const GapSizing: { [key: string]: string } = {
  "0": "0px",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3rem",
};

export const GapSizingEM: { [key: string]: string } = {
  "0": "0px",
  "1": "0.25em",
  "2": "0.5em",
  "3": "0.75em",
  "4": "1em",
  "5": "1.25em",
  "6": "1.5em",
  "7": "1.75em",
  "8": "2em",
  "9": "2.25em",
  "10": "2.5em",
  "11": "2.75em",
  "12": "3em",
};

export const GapSizeValues: string[] = Object.keys(GapSizing);
export type GapSizes = (typeof GapSizeValues)[number];
