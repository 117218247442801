import Vue from "vue";
import LoginAndRegister from "./LoginAndRegister.vue";

const mountpointQuery = "[data-js-bind='login-and-register']";
const dataQuery = "#login-and-register-data";

document.addEventListener("DOMContentLoaded", () => {
  const paramElement = document.querySelector(dataQuery);

  if (paramElement == null) {
    throw new Error("Couldn't find book login and register parameter json.");
  }

  const jsonData = JSON.parse(paramElement.innerHTML);

  new Vue({
    el: mountpointQuery,
    render: function (createElement) {
      return createElement(LoginAndRegister, { props: jsonData });
    },
  });
});
