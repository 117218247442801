<template>
  <div class="labeled-input" :class="{ 'labeled-input--has-error': error }">
    <label class="labeled-input__label" :for="`for-${id}`">{{ label }}</label>
    <input
      class="labeled-input__input"
      :id="`for-${id}`"
      v-model="internalValue"
      v-bind="this.$attrs"
      @blur="$emit('interacted-with') && $emit('blur')"
    />
    <div v-if="error && error.length" class="labeled-input__error">{{ error }}</div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,
  data() {
    return {
      id: null,
      internalValue: "",
    };
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    error: {
      type: [String, Boolean],
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
  },
  created() {
    this.internalValue = this.value;
  },
  mounted() {
    this.id = this._uid;
  },
  watch: {
    internalValue() {
      this.$emit("input", this.internalValue);
      this.$emit("interacted-with");
    },
  },
});
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "@scss/includes/framework.scss";

.labeled-input {
  $parent-fs: 20px; // assume 20px, set the font-size on this component from the parent container to achieve different sizing
  $bfs: 20px;
  font-size: emVal(math.div($bfs, 1px), $parent-fs);

  $baseClass: &;

  &__label {
    display: block;
    $fs: 16px;
    font-size: emVal(math.div($fs, 1px), $bfs);
    font-weight: $font-weight-semibold;
    line-height: emVal(19, $fs);
    margin: 0 0 emVal(16, $fs);
  }

  &__input {
    $fs: 16px;
    display: block;
    width: 100%;
    margin: 0;
    font-size: emVal(math.div($fs, 1px), $bfs);
    line-height: emVal(19, $fs);
    color: $black-color;
    background: $white-color;
    outline: 0;
    border: none;
    box-shadow: 0 0 0 1px $new-grey-color;
    border-radius: emVal(3, $fs);
    padding: emVal(14, $fs) emVal(16, $fs);

    &:focus {
      box-shadow: 0 0 0 1px darken($new-grey-color, 10%);
    }
  }

  &--has-error {
    #{$baseClass + "__input"} {
      box-shadow: 0 0 0 1px red;
    }
  }

  &__error {
    font-size: 0.5em;
    color: red;
    line-height: 1.8;
  }
}
</style>
