<template>
  <div :class="classObject" :style="styleObject" class="flex flex-row h-stack" v-on="$listeners">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { GapSizes, GapSizing, GapSizingEM } from "./";

export default Vue.extend({
  name: "HStack",
  props: {
    gap: {
      type: String as PropType<GapSizes>,
      default: "0",
    },
    center: {
      type: Boolean,
      default: false,
    },
    useEmSizing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    gapSizing(): string {
      return this.useEmSizing ? GapSizingEM[this.gap] : GapSizing[this.gap];
    },
    classObject(): { [key: string]: boolean } {
      return {
        "justify-center": this.center,
      };
    },
    styleObject(): { [key: string]: string } {
      return {
        "--hstack-gap-sizing": this.gapSizing,
      };
    },
  },
});
</script>

<style scoped>
.h-stack > *:first-child {
  margin-left: unset;
}

.h-stack > * {
  margin-left: var(--hstack-gap-sizing);
}

@tailwind utilities;
</style>
