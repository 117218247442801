import _ from "lodash";

export function amplitudeEvent(eventName, eventProperties = {}) {
  try {
    const globalProps = window.globalEventProperties || {};
    eventProperties = { ...globalProps, ...eventProperties }; // shallow merge
    window.amplitude.getInstance().logEvent(eventName, eventProperties);
  } catch (error) {
    console.error("BLOCKED: " + error);
  }
}

export function addClickEvent(elementId, eventName, eventProperties) {
  if (document.getElementById(elementId)) {
    document.getElementById(elementId).addEventListener("click", function () {
      amplitudeEvent(eventName, eventProperties);
    });
  }
}

export function addClickEventEl(expressionLanguage, eventName, eventProperties) {
  if (document.querySelector(expressionLanguage)) {
    document.querySelectorAll(expressionLanguage).forEach(function (s) {
      s.addEventListener("click", function () {
        amplitudeEvent(eventName, eventProperties);
      });
    });
  }
}

export function addLoadPageEvent(eventName, eventProperties) {
  window.addEventListener("DOMContentLoaded", function () {
    amplitudeEvent(eventName, eventProperties);
  });
}

export async function setUserProperties(user) {
  try {
    if (!_.isEmpty(user)) {
      window.amplitude.getInstance().setUserId(user.id);
      const userProps = {
        gender: user.details.gender,
        treatmentsPurchased: user.details.treatmentsPurchased,
        totalOrders: user.details.totalOrders,
        totalSpent: user.details.totalSpent,
        lastPurchasedDate: user.details.lastPurchasedDate,
        medicinePurchased: user.details.medicinePurchased,
        emailSubscribed: user.details.emailSubscribed,
        existingCustomer: user.details.existingCustomer,
        analyticsKey: user.details.analyticsKey,
        emailHash: await hashEmail(user.username),
      };
      window.amplitude.getInstance().setUserProperties(userProps);
    }

    const amplitudeUserId = window.amplitude.getInstance().getUserId();
    if (!_.isEmpty(user) && user.id !== amplitudeUserId) {
      window.amplitude.getInstance().logEvent("Login Success", {});
      await window.experiment.fetch();
    }
  } catch (error) {
    console.error("BLOCKED: " + error);
  }
}

async function hashEmail(emailStr) {
  const emailBuf = new TextEncoder().encode(emailStr);
  const hashBuffer = await crypto.subtle.digest("SHA-256", emailBuf);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

export function addUserProperties(props) {
  try {
    window.amplitude.getInstance().setUserProperties(props);
  } catch (error) {
    console.error("BLOCKED: " + error);
  }
}

export function sendCurrentPageEvent() {
  const url = new URL(document.location);

  const params = url.searchParams;

  if (params.has("redirected-from")) {
    const redirectedFrom = new URL(params.get("redirected-from"));
    const paramsToAppend = new URLSearchParams(params);
    paramsToAppend.delete("redirected-from");
    const path = url.pathname + "?" + paramsToAppend.toString();
    sendPageLoadedEvent(path, redirectedFrom.pathname);
  } else {
    const path = url.pathname + url.search;
    sendPageLoadedEvent(path);
  }
}

function sendPageLoadedEvent(path, redirectedFrom) {
  const deviceWidth = window.innerWidth;
  const deviceHeight = window.innerHeight;

  const props = {
    path: path,
  };

  window.amplitude.getInstance().setUserProperties({ deviceWidth, deviceHeight });

  Object.assign(props, redirectedFrom && { redirectedFrom });

  amplitudeEvent("page-loaded", props);
}

/** Global event properties will be included with all Amplitude events. */
export function addGlobalEventProperties(props) {
  try {
    const oldProps = window.globalEventProperties || {};
    const newProps = { ...oldProps, ...props }; // shallow merge
    window.globalEventProperties = newProps;
  } catch (error) {
    console.error("BLOCKED: " + error);
  }
}
