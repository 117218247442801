var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VStack',{staticClass:"lg:my-[35px] md:my-[24px]",class:{
    'rrx-login-page-updates': _vm.isRocketRx && _vm.redirectedFromChooseTreatment,
  },attrs:{"center":"","gap":"6","use-em-sizing":""}},[(_vm.showWeightLossProductCards)?_c('WeightLossProductCard'):_vm._e(),_vm._v(" "),(_vm.sectionTitle && !_vm.showWeightLossProductCards)?_c('div',{staticClass:"main-text-color text-[24px] font-[700] lg:mb-8 md:mb-8 lg:text-center md:text-center pt-8 px-8 section-title-login"},[_vm._v("\n    "+_vm._s(_vm.sectionTitle)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-[1.5em] items-center auth-container mx-auto text-[16px] wrapper xs:my-3 lg:rounded-[12px] md:rounded-[12px] lg:w-[461px] md:w-[461px] bg-white",class:{
      'md:py-[40px] gap-[16px]': _vm.showWeightLossProductCards,
    }},[_c('div',{class:{
        'main-text-color ': true,
        'lg:text-[24px] lg:font-[700] md:text-[24px] md:font-[700] sm:text-[16px] sm:font-[500] xs:font-[500] pt-4 md:pt-0 title-compact':
          _vm.sectionTitle,
        'text-[24px] font-[700] title-normal': !_vm.sectionTitle,
        'font-freight-text-pro': _vm.isLevity,
        'md:hidden !pt-0': _vm.showWeightLossProductCards,
      }},[_vm._v("\n      "+_vm._s(_vm.pageTitle)+"\n    ")]),_vm._v(" "),_c('Login',{attrs:{"google-client-id":_vm.googleClientId,"login-message-list":_vm.internalLoginMessageList,"update-message-list":_vm.updateLoginMessageList,"useWLProductCardExperimentCopy":_vm.showWeightLossProductCards,"app-theme":_vm.appTheme}}),_vm._v(" "),_c('div',{staticClass:"text-[#767676] text-[16px] font-[400]"},[_vm._v("— or —")]),_vm._v(" "),_c('div',{staticClass:"small-box w-full main-text-color"},[_c('div',{staticClass:"mx-8"},[_c('div',{attrs:{"id":"g_id_onload","data-client_id":_vm.googleClientId,"data-login_uri":"/oauth2/authorization/google","data-auto_prompt":"false"}}),_vm._v(" "),_c('div',{staticClass:"g_id_signin",attrs:{"data-type":"standard","data-size":"large","data-theme":"outline","data-text":"continue_with","data-shape":"rectangular","data-logo_alignment":"center","data-width":"320"}})])]),_vm._v(" "),(_vm.isRocketRx && _vm.redirectedFromChooseTreatment)?_c('ul',{staticClass:"flex flex-col gap-[12px] auth-benefits-list"},[_c('li',[_c('img',{attrs:{"src":"/resources/_rocketrx/svg/check-circle.svg","alt":"checked-icon"}}),_vm._v(" "),_c('span',[_vm._v("Save your answers for our U.S. medical team")])]),_vm._v(" "),_c('li',[_c('img',{attrs:{"src":"/resources/_rocketrx/svg/check-circle.svg","alt":"checked-icon"}}),_vm._v(" "),_c('span',[_vm._v("Track the arrival of your FDA-approved treatments")])]),_vm._v(" "),_c('li',[_c('img',{attrs:{"src":"/resources/_rocketrx/svg/check-circle.svg","alt":"checked-icon"}}),_vm._v(" "),_c('span',[_vm._v("Access your treatment plan anytime")])])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }