<template>
  <form
    ref="loginForm"
    class="max-w-[320px] w-full text-center auth-login"
    method="post"
    action="/login"
    @submit="formSubmission"
  >
    <div data-hj-suppress="true">
      <input
        ref="email"
        class="auth-login__input"
        placeholder="Enter your email address..."
        id="username"
        name="username"
        tabindex="1"
        type="email"
        autocomplete="on"
        :disabled="submitting"
        required
      />

      <div v-if="showLoginForm">
        <div class="auth-login__password-wrap">
          <input
            class="auth-login__input"
            placeholder="Enter your password..."
            id="password"
            name="password"
            tabindex="2"
            type="password"
            autocomplete="on"
            :disabled="submitting"
            required
          />
          <img
            :src="imageHostUrl + '/medexpress-resources/svg/visible-off.svg'"
            v-on:click="showPassword"
            v-if="passwordHidden"
            class="auth-login__password-toggle"
            alt="show/hide password"
          />
          <img
            :src="imageHostUrl + '/medexpress-resources/svg/visible-on.svg'"
            v-on:click="hidePassword"
            v-if="!passwordHidden"
            class="auth-login__password-toggle"
            alt="show/hide password"
          />
        </div>

        <div id="login-captcha" v-if="requiresCaptcha">
          <div
            class="g-recaptcha auth-login__recaptcha"
            id="login-captcha-stub"
            data-callback="enableSubmit"
            data-expired-callback="disableSubmit"
            :data-sitekey="captchaServiceKey"
          />
        </div>
      </div>
    </div>

    <div
      v-if="loginMessageList.length || (requestParamError && requestParamError.length)"
      aria-live="polite"
      class="auth-login__errors"
    >
      <div v-for="(message, idx) in loginMessageList" :key="idx">
        {{ message }}
      </div>
      <div v-if="requestParamError && requestParamError.length">
        {{ requestParamError }}
      </div>
    </div>

    <div v-if="requestParamMessage && requestParamMessage.length" aria-live="polite" class="auth-login__message">
      {{ requestParamMessage }}
    </div>

    <button
      class="auth-login__login-btn"
      id="loginButton"
      name="loginButton"
      tabindex="5"
      :aria-disabled="submitting || (showLoginForm && requiresCaptcha && captchaUnsolved)"
      :disabled="submitting || (showLoginForm && requiresCaptcha && captchaUnsolved)"
    >
      <span v-if="submitting"> {{ getLoadingText() }} </span>
      <span class="flex items-center justify-center gap-[4px]" v-else>
        {{ getSubmitText() }}
        <ArrowRight v-if="useWLProductCardExperimentCopy" />
      </span>
    </button>

    <div v-if="showLoginForm" class="auth-login__forgot-btn-wrap">
      <a href="/forgot-password" class="auth-login__forgot-btn" data-toggle="modal">Forgotten your password?</a>
    </div>
  </form>
</template>

<script lang="ts">
declare global {
  interface Window {
    dataLayer: any[];
  }
}
import Vue from "vue";
import $ from "jquery";
import { AuthServiceInstance } from "AuthTypes";

import LabeledInput from "../ComponentLibrary/LabeledInput.vue";
import { amplitudeEvent } from "../MXPixel/MedexpressAmplitude";
import AuthService from "./AuthService";
import ArrowRight from "@/ComponentLibrary/assets/arrow_right.svg?inline";

interface LoginDataTypes {
  requestParamError: null | string;
  requestParamMessage: null | string;
  submitting: boolean;
  passwordHidden: boolean;
  captchaUnsolved: boolean;
  showLoginForm: boolean;
  greCaptchaId: null | string;
  authService: AuthServiceInstance;
}

export default Vue.extend({
  inject: ["_csrf", "imageHostUrl", "queryParameters", "requiresCaptcha", "captchaServiceKey"],
  components: {
    LabeledInput,
    ArrowRight,
  },
  props: {
    googleClientId: {
      type: String,
      required: true,
    },
    loginMessageList: {
      type: Array,
      default: () => [],
    },
    updateMessageList: {
      type: Function,
      required: true,
    },
    useWLProductCardExperimentCopy: {
      type: Boolean,
      required: false,
    },
    appTheme: {
      type: String,
      required: true,
    },
  },
  data(): LoginDataTypes {
    return {
      requestParamError: null,
      requestParamMessage: null,
      submitting: false,
      passwordHidden: true,
      captchaUnsolved: true,
      greCaptchaId: null,
      showLoginForm: false,
      authService: new AuthService({
        _csrf: this._csrf,
        region: process.env.REGION,
      }),
    };
  },
  computed: {
    redirectedFromChooseTreatment(): boolean {
      const ulrParam = new URLSearchParams(window.location.search);
      return ulrParam.get("from-treatment") == "true";
    },
    isRocketRx(): boolean {
      return this.appTheme === "rocketrx";
    },
  },
  created() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "checkout_progress",
      checkout_step: 5,
      checkout_option: "Login",
    });

    this.requestParamError = this.queryParameters["error"];
    this.requestParamMessage = this.queryParameters["message"];

    window.enableSubmit = () => {
      this.captchaUnsolved = false;
    };
    window.disableSubmit = () => {
      this.captchaUnsolved = true;
    };

    this.renderReCaptcha();
  },
  mounted() {
    amplitudeEvent("View Login Screen");
  },
  methods: {
    formSubmission(event: Event) {
      event.preventDefault();

      this.requestParamError = "";
      this.updateMessageList([]);
      this.requestParamMessage = "";

      if (!this.showLoginForm) {
        const username = (this.$refs.email as HTMLInputElement).value;
        this.submitting = true;
        this.authService
          .checkUsername(username)
          .then(() => {
            this.showLoginForm = true;
            this.submitting = false;
            amplitudeEvent("Email Login");
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.goToRegistrationFor(username);
              amplitudeEvent("Email Register");
            } else {
              this.submitting = false;
              throw error;
            }
          });
        return false;
      }

      const formData = new FormData(this.$refs.loginForm as HTMLFormElement);
      this.submitting = true;
      this.authService
        .login(formData)
        .then(({ redirectUrl }) => {
          window.location.replace(redirectUrl as string);
        })
        .catch((errorObject) => {
          amplitudeEvent("Login Fail");
          this.submitting = false;
          const { messagesList } = errorObject;

          this.updateMessageList(messagesList);
          this.renderReCaptcha();
        });

      return false;
    },
    renderReCaptcha() {
      this.authService.captchaStatus().then((requiresCaptcha) => {
        this.requiresCaptcha = requiresCaptcha;
        if (requiresCaptcha) {
          window.disableSubmit();
          window.grecaptcha.ready(() => {
            if (this.greCaptchaId !== null) {
              window.grecaptcha.reset(this.greCaptchaId);
            } else {
              this.greCaptchaId = window.grecaptcha.render("login-captcha-stub", {});
            }
          });
        }
      });
    },
    showPassword() {
      $("#password").attr("type", "text");
      this.passwordHidden = false;
    },
    hidePassword() {
      $("#password").attr("type", "password");
      this.passwordHidden = true;
    },
    goToRegistrationFor(username: string) {
      const searchParams = new URLSearchParams(window.location.search);
      const usState = searchParams.get("usState");
      window.location.assign(
        `/register?username=${encodeURIComponent(username)}${usState ? `&usState=${usState}` : ""}`
      );
    },
    getLoadingText(): string {
      return this.showLoginForm ? "Signing in..." : "Loading...";
    },
    getSubmitText(): string {
      if (this.useWLProductCardExperimentCopy) {
        return this.showLoginForm ? "See my treatments" : "Next";
      }

      const defaultCopy = this.showLoginForm ? "Continue" : "Continue with email";
      return this.isRocketRx && this.redirectedFromChooseTreatment ? "Log in / sign up" : defaultCopy;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/Auth/scss/includes";

@import "@theme/Auth/Login.scss";
</style>
