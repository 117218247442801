<template>
  <VStack
    center
    class="lg:my-[35px] md:my-[24px]"
    :class="{
      'rrx-login-page-updates': isRocketRx && redirectedFromChooseTreatment,
    }"
    gap="6"
    use-em-sizing
  >
    <WeightLossProductCard v-if="showWeightLossProductCards" />
    <div
      v-if="sectionTitle && !showWeightLossProductCards"
      class="main-text-color text-[24px] font-[700] lg:mb-8 md:mb-8 lg:text-center md:text-center pt-8 px-8 section-title-login"
    >
      {{ sectionTitle }}
    </div>
    <div
      class="flex flex-col gap-[1.5em] items-center auth-container mx-auto text-[16px] wrapper xs:my-3 lg:rounded-[12px] md:rounded-[12px] lg:w-[461px] md:w-[461px] bg-white"
      :class="{
        'md:py-[40px] gap-[16px]': showWeightLossProductCards,
      }"
    >
      <div
        :class="{
          'main-text-color ': true,
          'lg:text-[24px] lg:font-[700] md:text-[24px] md:font-[700] sm:text-[16px] sm:font-[500] xs:font-[500] pt-4 md:pt-0 title-compact':
            sectionTitle,
          'text-[24px] font-[700] title-normal': !sectionTitle,
          'font-freight-text-pro': isLevity,
          'md:hidden !pt-0': showWeightLossProductCards,
        }"
      >
        {{ pageTitle }}
      </div>
      <Login
        :google-client-id="googleClientId"
        :login-message-list="internalLoginMessageList"
        :update-message-list="updateLoginMessageList"
        :useWLProductCardExperimentCopy="showWeightLossProductCards"
        :app-theme="appTheme"
      />
      <div class="text-[#767676] text-[16px] font-[400]">&mdash; or &mdash;</div>
      <div class="small-box w-full main-text-color">
        <div class="mx-8">
          <div
            id="g_id_onload"
            :data-client_id="googleClientId"
            data-login_uri="/oauth2/authorization/google"
            data-auto_prompt="false"
          ></div>
          <div
            class="g_id_signin"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="continue_with"
            data-shape="rectangular"
            data-logo_alignment="center"
            data-width="320"
          ></div>
        </div>
      </div>
      <ul class="flex flex-col gap-[12px] auth-benefits-list" v-if="isRocketRx && redirectedFromChooseTreatment">
        <li>
          <img src="/resources/_rocketrx/svg/check-circle.svg" alt="checked-icon" />
          <span>Save your answers for our U.S. medical team</span>
        </li>
        <li>
          <img src="/resources/_rocketrx/svg/check-circle.svg" alt="checked-icon" />
          <span>Track the arrival of your FDA-approved treatments</span>
        </li>
        <li>
          <img src="/resources/_rocketrx/svg/check-circle.svg" alt="checked-icon" />
          <span>Access your treatment plan anytime</span>
        </li>
      </ul>
    </div>
  </VStack>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { VStack } from "../Layout/Stack";
import Login from "./Login";
import WeightLossProductCard from "./WeightLossProductCard.vue";
import experiment from "amplitude-experiment";

export default Vue.extend({
  components: { VStack, Login, WeightLossProductCard },
  props: {
    googleClientId: {
      type: String,
      required: true,
    },
    appTheme: {
      type: String,
      required: true,
    },
    imageHostUrl: {
      type: String,
      required: true,
    },
    _csrf: {
      type: Object,
      required: true,
    },
    captchaServiceKey: {
      type: String,
      required: true,
    },
    queryParameters: {
      type: Object,
      required: false,
    },
    requiresCaptcha: {
      type: Boolean,
      required: true,
    },
    loginMessageList: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    productCategory: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      internalLoginMessageList: this.loginMessageList,
    };
  },
  provide() {
    return {
      _csrf: this._csrf,
      imageHostUrl: this.imageHostUrl,
      captchaServiceKey: this.captchaServiceKey,
      queryParameters: this.queryParameters,
      requiresCaptcha: this.requiresCaptcha,
      productCategory: this.productCategory,
    };
  },
  mounted() {
    //Loding the google sso script here to avoid any race conditions
    //between the loading of button html and the js script
    const googleScript = document.createElement("script");
    googleScript.setAttribute("src", "https://accounts.google.com/gsi/client");
    document.head.appendChild(googleScript);
  },
  computed: {
    redirectedFromChooseTreatment(): boolean {
      const ulrParam = new URLSearchParams(window.location.search);
      return ulrParam.get("from-treatment") == "true";
    },
    sectionTitle(): string | null {
      // TODO [CS-693] - check that this small copy change is fine
      if (this.redirectedFromChooseTreatment) {
        return this.isRocketRx
          ? "Save your answers and explore treatment options"
          : "Login or register to choose your preferred treatment";
      }
      return null;
    },
    pageTitle(): string | null {
      if (this.showWeightLossProductCards) {
        return "Log in or register";
      }

      const copy =
        this.isRocketRx && this.redirectedFromChooseTreatment
          ? "Save your answers and explore treatment options"
          : "Sign up or log in";
      return copy;
    },
    isLevity(): boolean {
      return this.appTheme === "levity";
    },
    isRocketRx(): boolean {
      return this.appTheme === "rocketrx";
    },
    showWeightLossProductCards(): boolean {
      return (
        this.productCategory === "WeightLoss" &&
        experiment.variant("gro-2182-login-wl-product-card").value === "variant"
      );
    },
  },
  methods: {
    updateLoginMessageList(messageList: Array<string>) {
      this.internalLoginMessageList = messageList;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/Auth/scss/includes";
@import "@theme/Auth/LoginRegisterWithSSO.scss";

.font-freight-text-pro {
  font-family: "freight-text-pro";
}

.auth-container {
  padding: 48px 24px 64px 24px;

  @media (max-width: 767px) {
    padding: 0 0 24px 0;
    box-shadow: none;
    width: 100%;
  }

  box-shadow: 0 2px 12px 0 rgb(104 104 104 / 20%);
}

.title-compact {
  @include mobile {
    margin-bottom: -12px;
    width: 320px;
  }
}

.title-normal {
  @include mobile {
    padding-top: 24px;
  }
}

.small-box {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  row-gap: 16px;
}

.section-title-login {
  @media (max-width: 767px) {
    width: 320px;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
